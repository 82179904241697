import React, { Component } from 'react';
import './index.scss';
import css from './index.module.scss';

import {
	EditorState,
	convertToRaw,
	convertFromRaw,
	//ContentState,
} from 'draft-js';
import { /*RawDraftContentState,*/ Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { message } from 'antd';
import 'antd/lib/message/style/index.css';

import draftToHtml from 'draftjs-to-html';
//import htmlToDraft from 'html-to-draftjs';
import draftToMarkdown from 'draftjs-to-markdown';

import pub from '@/public/pub';

import { myBlockRenderer } from './customBlockRenderFunc.jsx';

const storageName = 'react-draft-wysiwyg-rich-text-editor-content-raw';
const storageNameForHtml = 'react-draft-wysiwyg-rich-text-editor-content-html';
const storageNameForMarkdown = 'react-draft-wysiwyg-rich-text-editor-content-markdown';
const clearEditorName = 'react-draft-wysiwyg-rich-text-editor-clear';

export default class MyEditor extends Component {
	constructor(props) {
		super(props);
	}
	state = {
		editorState: EditorState.createEmpty(),
		placeholderText: (() => {
			const language = document.documentElement.getAttribute('lang');
			const placeholderTextArr =
				language === 'zh-CN'
					? [
							'雁过留声，许下你的心愿 ...',
							'你是我一生只会遇见一次的惊喜 ...',
							'愿世间美好与你环环相扣 ...',
							'期待您的留言评论 ...',
							'有许多推心置腹的话,差一个畅所欲言的你 ...',
							'与其互发许多表情，不如和你面对面表露心情 ...',
					  ]
					: ['ئۇچۇر قالدۇرغىنىڭىزنى قارشى ئالىمىز'];
			return '' + placeholderTextArr[Math.floor(Math.random() * placeholderTextArr.length)];
		})(),
	};
	onEditorStateChange = editorState => {
		this.setState(
			{
				editorState,
			},
			() => {
				const raw = convertToRaw(editorState.getCurrentContent());
				//存储至状态管理器，
				pub.set(storageName, raw);
				pub.set(storageNameForHtml, draftToHtml(raw));
				pub.set(storageNameForMarkdown, draftToMarkdown(raw));
				//存储至浏览器本地
				localStorage.setItem(storageName, JSON.stringify(raw));
			}
		);
	};
	componentDidMount() {
		//定义清空的函数
		pub.set(clearEditorName, (isShowMessage = false) => {
			this.setState({ editorState: EditorState.createEmpty() }, () => {
				//将本地存储清空
				localStorage.setItem(storageName, '{}');
				//将缓存区清空
				pub.set(storageName, {});
				//显示信息
				isShowMessage &&
					message.success({
						content: '已清空编辑器输入的内容',
						duration: 3,
						style: {
							//opacity: 0.9,
						},
					});
			});
			//alert('已清空');
		});
		//组件渲染时检测是否存在内容
		const raw =
			pub.get(storageName) ||
			(() => {
				let res = {};
				try {
					res = JSON.parse(localStorage.getItem(storageName) || '{}');
				} catch (e) {
					//
				}
				pub.set(storageName, res);
				if (res && res.blocks && res.entityMap) {
					if (!(res.blocks.length == 0 || (res.blocks.length === 1 && !res.blocks[0].text))) {
						message.info({
							content: '文本编辑器读取了草稿',
							duration: 1,
							style: {
								opacity: 0.9,
							},
						});
					} else {
						res = {};
					}
				}
				return res;
			})();
		if (raw && typeof raw === 'object' && Array.isArray(raw.blocks) && typeof raw.entityMap !== 'undefined') {
			const content = convertFromRaw(raw);
			//console.log('读取到了', raw, content);
			const editorState = EditorState.createWithContent(content);
			this.setState({
				editorState,
			});
		}
		//const content = convertFromRaw(raw);
		//如果有上次未保存的状态，则更改状态
	}
	render() {
		const { editorState, placeholderText } = this.state;
		//console.log(RawDraftContentState);
		return (
			<div className={css.container} data-draft-wysiwyg-container>
				<Editor
					//toolbarHidden
					editorState={editorState}
					wrapperClassName={css['wrapper-container-d']}
					toolbarClassName={css['toolbar-container-d']}
					editorClassName={css['editor-container-d']}
					onEditorStateChange={this.onEditorStateChange}
					placeholder={placeholderText} //雁过留声，许下你的心愿，你是我一生只会遇见一次的惊喜 ...
					//customBlockRenderFunc={myBlockRenderer}
					toolbar={{
						options: [
							'emoji',
							'image',
							'blockType',
							'link',
							//'inline',
							'history',
							'remove',
						],
						blockType: {
							inDropdown: true,
							options: ['Normal', 'Code', 'Blockquote'],
						},

						inline: { inDropdown: true },
						list: { inDropdown: true },
						textAlign: { inDropdown: true },
						link: { inDropdown: true },
						history: { inDropdown: true },
					}}
					localization={{
						locale: 'zh',
					}}
					//toolbarCustomButtons={[() => <div>你说呢</div>]}
				/>
				{false && (
					<>
						<textarea name="contentHtml" placeholder="html" className={css['textarea-to-html']} hidden disabled value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />
						<textarea
							name="contentMarkdown"
							placeholder="markdown"
							className={css['textarea-to-markdown']}
							hidden
							disabled
							value={draftToMarkdown(convertToRaw(editorState.getCurrentContent()))}
						/>
					</>
				)}
			</div>
		);
	}
}
