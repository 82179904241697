import { defineComponent, onMounted, ref } from '@vue/composition-api';

import 'babel-polyfill';
//import 'react-app-polyfill/ie11'; //react兼容IE11

import { createElement } from 'react';
import ReactDOM from 'react-dom';

import ReactRoot from './react-root.jsx'; //问题出在这里

import pub from '@/public/pub';

export default {
    setup() {
        if (!pub.get('isInitDraft')) {
            console.log(
                '%cReact Draft%chttps://draftjs.org/',
                'padding:8px 4px;background-color:#843131;color:white;font-weight:bold;',
                'padding:8px 4px;background-color:#000000;color:#a21717'
            );
            pub.set('isInitDraft', true);
        }
        const reactAPP = ref(null);
        onMounted(() => {
            ReactDOM.render(
                createElement(ReactRoot),
                reactAPP.value || document.querySelector(['data-react-app'])
            );
        });
        return { reactAPP };
    },
};
